import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { downloadFile } from '@/main/utils/functions'
import api from '@/infra/api'
import { ETipoPendencia } from '@/main/enums'

export const useOrdersFiles = ({ orderId, orderNumber }: { orderId: string; orderNumber: string | number }) => {
  const getOrderLayout = useMutation({
    mutationFn: ({
      orderId,
      pendencyType = ETipoPendencia.Pedido,
    }: {
      orderId: string
      pendencyType?: ETipoPendencia
    }) => api.get(`/revenda-pedidos-bff/pedido/gerararquivo/${orderId}/${pendencyType}`),
    onSuccess: (response, { pendencyType }) => {
      const file = response?.data?.valor?.base64Result
      if (file) {
        downloadFile({
          download: `Layout ${
            ETipoPendencia.Colaborador === pendencyType ? 'de Colaboradores' : 'do Pedido'
          } ${orderNumber}.txt`,
          href: `data:text/plain;base64,${file}`,
        })
        updateDownloadOption('orderLayout', false)
      }
    },
  })

  const {
    data: orderXlsx,
    refetch: getOrderXlsx,
    isFetching: isLoadingOrderXlsx,
  } = useQuery({
    queryKey: ['brokerOrderXlsx', orderId],
    queryFn: () => api.get(`resale-bff/orders/${orderId}/exportexcelorderitems`, { responseType: 'blob' }),
    enabled: false,
  })

  const { refetch: getAttachedSlip, isFetching: isLoadingAttachedSlip } = useQuery({
    queryKey: ['brokerAttachedSlip', orderId],
    queryFn: () => api.get(`/resale-bff/orders/${orderId}/download/slip`),
    enabled: false,
    onSuccess: (res) => {
      window.open(res?.data?.temporaryLink, '_blank')
    },
  })

  const { refetch: getPaymentProof, isFetching: isLoadingPaymentProof } = useQuery({
    queryKey: ['brokerPaymentProof', orderId],
    queryFn: () => api.get(`/resale-bff/orders/${orderId}/download/proof-Payment`),
    enabled: false,
    onSuccess: (res) => {
      window.open(res?.data?.temporaryLink, '_blank')
    },
  })

  const [shouldDownload, setShouldDownload] = useState({ orderLayout: false, orderXlsx: false })

  const updateDownloadOption = (file: string, value: boolean) =>
    setShouldDownload((state) => ({ ...state, [file]: value }))

  // Auto download  Order Xlsx
  useEffect(() => {
    const res = orderXlsx?.data?.valor?.base64Result
    if (res && shouldDownload.orderXlsx) {
      const data = new Blob([res?.data as any], { type: 'text/xls' })
      const csvURL = window.URL.createObjectURL(data)
      downloadFile({
        href: csvURL,
        download: `Pedido ${orderNumber}.xlsx`,
      })
      updateDownloadOption('orderXlsx', false)
    }
  }, [orderXlsx, orderNumber, shouldDownload])

  return {
    getOrderLayout,
    getOrderXlsx: () => {
      getOrderXlsx()
      updateDownloadOption('orderXlsx', true)
    },
    getPaymentProof,
    isLoadingPaymentProof,
    getAttachedSlip,
    isLoadingAttachedSlip,
    isLoadingOrderXlsx,
  }
}
