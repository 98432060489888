import api from '@/infra/api'
import { useNotifications } from '@/main/hooks'
import { IStandardResponse } from '@/main/interfaces'
import { EMessageType } from '@/main/store'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useOrderMutations = () => {
  const queryClient = useQueryClient()
  const notifications = useNotifications()

  const uploadProjectionFile = useMutation({
    mutationKey: ['brokerOrderuploadProjectionFile'],
    mutationFn: (data: { file: File; orderId: string; companyId: string; purchaseId: string }) => {
      const payload = new FormData()
      payload.append('arquivo', data.file)
      payload.append('numeroPedido', data.orderId)
      payload.append('numeroCompra', data.purchaseId)
      payload.append('idEmpresa', data.companyId)
      return api.patch<IStandardResponse<{ quantidade: number }>>('revenda-pedidos-bff/pedido/salvarSaldo', payload)
    },
  })

  const uploadBrokerSlip = useMutation({
    mutationFn: (data: { file: File; orderId: string; companyId: string; purchaseId: string }) => {
      const payload = new FormData()
      payload.append('arquivo', data.file)
      payload.append('numeroPedido', data.orderId)
      payload.append('numeroCompra', data.purchaseId)
      payload.append('idEmpresa', data.companyId)
      return api.patch(`revenda-pedidos-bff/pedido/processarOperadora`, payload)
    },
    onSuccess: () => {
      notifications.push({ content: 'Arquivo enviado com sucesso!', type: EMessageType.Success })
      queryClient.invalidateQueries({ queryKey: ['purchases'] })
    },
  })

  const uploadOrderPaymentProof = useMutation({
    mutationFn: (data: { file: any; orderNumber: string; orderId: string; purchaseId: string }) => {
      const payload = new FormData()
      payload.append('arquivo', data.file)
      payload.append('codigoPedido', data.orderNumber)
      payload.append('numeroPedido', data.orderId)
      payload.append('idCompra', data.purchaseId)
      return api.patch(`revenda-pedidos-bff/pedido/concluir`, payload)
    },
    onSuccess: () => {
      notifications.push({ content: 'Arquivo enviado com sucesso!', type: EMessageType.Success })
      queryClient.invalidateQueries({ queryKey: ['purchases'] })
    },
  })

  const startBrokerOrderProcessing = useMutation({
    mutationFn: ({ orderId, useBalanceProjection }: { orderId: string; useBalanceProjection?: boolean }) =>
      api.patch(`revenda-pedidos-bff/pedido/processar`, { numeroPedido: orderId, projetarSaldo: useBalanceProjection }),
    onSuccess: () => {
      notifications.push({ content: 'Atendimento iniciado!', type: EMessageType.Success })
      queryClient.invalidateQueries({ queryKey: ['purchases'] })
    },
  })

  /* const finalizeOrder = useMutation({
    mutationFn: ({ orderId, deliveryCode }: { orderId: string; deliveryCode: string }) =>
      api.post(`resale-bff/orders/${orderId}/finalize?deliveryCode=${deliveryCode}`),
    onSuccess: () => {
      notifications.push({ content: 'Pedido finalizado!', type: EMessageType.Success })
      invalidateList(queryClient)
      config.finalizeOrder.onSuccess && config.finalizeOrder.onSuccess()
    },
  })

  ) */

  return {
    startBrokerOrderProcessing,
    uploadProjectionFile,
    uploadBrokerSlip,
    uploadOrderPaymentProof,
  }
}
