import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FileUpload,
  Skeleton,
  Typography,
} from '@stationkim/front-ui'
import { useState } from 'react'

interface PaymentProofUploadDialogProps {
  onClose: () => void
  onConfirm: (file: any) => void
  isLoading: boolean
}

export const PaymentProofUploadDialog = ({ onClose, onConfirm, isLoading }: PaymentProofUploadDialogProps) => {
  const [file, setFile] = useState<any>(null)
  return (
    <Dialog open onClose={isLoading ? null : onClose}>
      <DialogTitle>Comprovante de pagamento</DialogTitle>
      <DialogContent>
        <Typography variant='body2' sx={{ margin: '16px 0' }}>
          Para confirmar o pagamento é necessário que faça o upload do comprovante.
        </Typography>
        {isLoading ? (
          <Skeleton variant='rounded' height='156px' />
        ) : (
          <FileUpload
            accept={{
              'application/pdf': ['.pdf'],
            }}
            onChange={setFile}
          />
        )}
        <Typography sx={{ fontSize: '.875rem', margin: '0 auto 16px auto' }}>
          Após confirmação do pagamento não será mais possível exportar o layout.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' sx={{ filter: 'grayscale(100)' }} onClick={onClose} fullWidth disabled={isLoading}>
          Cancelar
        </Button>
        <Button variant='contained' onClick={() => onConfirm(file)} fullWidth disabled={!file || isLoading}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
