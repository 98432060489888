export const WarnIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 2.5C15.5078 2.5 20 6.99219 20 12.5C20 18.0469 15.5078 22.5 10 22.5C4.45312 22.5 0 18.0469 0 12.5C0 6.99219 4.45312 2.5 10 2.5ZM10 20.625C14.4531 20.625 18.125 16.9922 18.125 12.5C18.125 8.04688 14.4531 4.375 10 4.375C5.50781 4.375 1.875 8.04688 1.875 12.5C1.875 16.9922 5.50781 20.625 10 20.625ZM10 14.375C9.45312 14.375 9.0625 13.9844 9.0625 13.4375V8.4375C9.0625 7.92969 9.45312 7.5 10 7.5C10.5078 7.5 10.9375 7.92969 10.9375 8.4375V13.4375C10.9375 13.9844 10.5078 14.375 10 14.375ZM10 15.7031C10.6641 15.7031 11.2109 16.25 11.2109 16.9141C11.2109 17.5781 10.6641 18.125 10 18.125C9.29688 18.125 8.75 17.5781 8.75 16.9141C8.75 16.25 9.29688 15.7031 10 15.7031Z'
        fill='#D32F2F'
      />
    </svg>
  )
}
