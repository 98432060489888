import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
} from '@/presentation/components/atoms'
import { useOrderMutations } from '../../../hooks/useOrderMutations'
import { Form } from './styles'
import { useForm } from 'react-hook-form'
import { schema } from './utils/validations'
import { yupResolver } from '@hookform/resolvers/yup'

export const FinishPickUpOrderDialog = ({ onClose, orderToFinish, open }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const { finalizeOrder } = useOrderMutations({
    config: {
      finalizeOrder: {
        onSuccess: () => {
          onClose()
        },
      },
    },
  })

  const submit = (data) => {
    finalizeOrder.mutate({ orderId: orderToFinish, deliveryCode: data?.code })
  }

  const isLoading = finalizeOrder.isLoading

  return (
    <Dialog open={open} onClose={isLoading ? null : () => onClose()}>
      <DialogTitle id='alert-dialog-title' sx={{ marginBottom: '32px' }}>
        Concluir pedido
      </DialogTitle>
      <DialogContent
        sx={{
          width: '536px',
          padding: '0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: isLoading ? 'center' : 'start',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <p>Para concluir o pedido, informe o ID da entrega. Após concluída, a ação não poderá ser desfeita.</p>
            <Form onSubmit={handleSubmit(submit)} style={{ width: '100%' }}>
              <div>
                <label>Informe o ID da entrega</label>
                <input placeholder='000000' {...register('code', { required: 'Campo obrigatório!' })} maxLength={15} />
                <span>{(errors?.code?.message || '') as string}</span>
              </div>
              <DialogActions sx={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: '32px' }}>
                <Button disabled={isLoading} variant='outlined' onClick={onClose}>
                  Cancelar
                </Button>
                <Button disabled={isLoading} variant='contained' type='submit' autoFocus>
                  Confirmar
                </Button>
              </DialogActions>
            </Form>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
