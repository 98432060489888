import React, { useContext, useState } from 'react'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { Box, Button, Icon, Menu, MenuItem, Tooltip } from '@stationkim/front-ui'
import { EStatusPedido } from '@/main/enums'
import { ListOrderContext } from '../../../../listOrders'
import { useOrdersFiles } from '../../../../hooks/useOrdersFiles'
import { SlipUploadDialog } from './components/slipUploadDialog'
import { useOrderMutations } from '../../../../hooks/useOrderMutations'
import { PaymentProofUploadDialog } from './components/paymentProofUploadDialog'
import { IOrder } from '../../../../hooks/useOrderList'

type TMenuItem = {
  title: string
  action: () => void
  disabled?: boolean
  visible?: boolean
  tooltip?: string
}

export const OrderMenu = ({ order }: { order: IOrder }) => {
  const { setOrderToDetail } = useContext(ListOrderContext)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false)
  const [isPaymentProofDialogOpen, setIsPaymentProofDialogOpen] = useState(false)
  const { uploadBrokerSlip, uploadOrderPaymentProof } = useOrderMutations()

  const { getOrderLayout, getOrderXlsx } = useOrdersFiles({
    orderId: order.id,
    orderNumber: order.orderNumber,
  })

  const isUnderTreatment =
    order.orderStatus > EStatusPedido.AguardandoProcessamento && order.orderStatus < EStatusPedido.DisponivelRetirada
  const isRunning = order.orderStatus === EStatusPedido.EmAndamento

  const items: TMenuItem[] = [{ action: () => setOrderToDetail(() => order), title: 'Mais Informações' }]

  if (isUnderTreatment)
    items.push(
      { action: () => getOrderLayout.mutate({ orderId: order.id }), title: 'Download de pedido' },
      { action: getOrderXlsx, title: 'Exportar arquivo de pedido' },
      { action: () => setIsUploadDialogOpen(true), title: 'Anexar boleto gerado' },
      {
        action: () => setIsPaymentProofDialogOpen(true),
        title: 'Confirmar pagamento',
        disabled: !isRunning,
        tooltip: 'Para confirmar o pagamento é preciso anexar o boleto da operadora',
      },
    )

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  return (
    <>
      <Button
        sx={{
          minWidth: 'unset',
          width: '32px',
          height: '32px',
          padding: '0',
          borderRadius: '50%',
          color: '#0000008A',
        }}
        onClick={handleClick}
      >
        <Icon icon={faEllipsisVertical} />
      </Button>
      <Menu id='basic-menu' anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ width: '280px' }}>
        {items.map(({ action, title, disabled, tooltip }, index) => (
          <Tooltip title={tooltip} key={'order_menu_' + index} placement='top'>
            <Box>
              <MenuItem onClick={action} disabled={disabled}>
                {title}
              </MenuItem>
            </Box>
          </Tooltip>
        ))}
      </Menu>
      {isPaymentProofDialogOpen && (
        <PaymentProofUploadDialog
          onClose={() => setIsPaymentProofDialogOpen(false)}
          isLoading={uploadOrderPaymentProof.isLoading}
          onConfirm={(e) => {
            uploadOrderPaymentProof.mutate(
              {
                file: e,
                orderNumber: String(order.orderNumber),
                orderId: order.id,
                purchaseId: order.purchaseId,
              },
              {
                onSuccess: () => setIsPaymentProofDialogOpen(false),
              },
            )
          }}
        />
      )}
      {isUploadDialogOpen && (
        <SlipUploadDialog
          onClose={() => setIsUploadDialogOpen(false)}
          onConfirm={(file: File) => {
            uploadBrokerSlip.mutate(
              {
                file,
                companyId: order.companyId,
                orderId: order.id,
                purchaseId: order.purchaseId,
              },
              {
                onSuccess: () => setIsUploadDialogOpen(false),
              },
            )
          }}
          isLoading={uploadBrokerSlip.isLoading}
        />
      )}
    </>
  )
}
