import { ChipProps, Theme, useTheme } from '@mui/material'
import { StyledChip } from './styles'
import { EOrderPendency } from '@/main/enums'

interface PendencyChipProps extends ChipProps {
  pendency: EOrderPendency
  onClick?: () => void
}

const getPendencyStyle = (theme: Theme): Record<EOrderPendency, { color: string; label: string }> => ({
  [EOrderPendency.Pending]: { color: theme.palette.primary.dark, label: 'Pendência' },
  [EOrderPendency.Resolved]: { color: theme.palette.success.dark, label: 'Resolvida' },
  [EOrderPendency.None]: { color: theme.palette.grey[700], label: 'N/A' },
})

export const PendencyChip = ({ pendency, onClick }: PendencyChipProps) => {
  const theme = useTheme()
  const pendencyStyle = getPendencyStyle(theme)[pendency]

  const handlePendencyClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (onClick) onClick()
  }
  if (!pendencyStyle) return null
  return <StyledChip label={pendencyStyle.label} sx={{ color: pendencyStyle.color }} onClick={handlePendencyClick} />
}
