import { createContext, useMemo, useState } from 'react'
import {
  Box,
  ColumnsSelector,
  usePersistentTableConfig,
  organizeColumnsByOptions,
  ColumnsSelectorProps,
  Typography,
  ColumnOption,
  AutoTableVirtualized,
  atomWithHashAndName,
  usePersistentFilterWithHash,
} from '@stationkim/front-ui'
import { Panel, PageHeader } from '@/presentation/components/molecules'
import { purchaseTableColumns, purchaseTableColumnsOptions } from './utils/purchaseTableColumns'
import { IOrder, useOrderList } from './hooks/useOrderList'
import { ExpandIcon } from './components/expandIcon/expandIcon'
import { InnerTable } from './components/innerTable/innerTable'
import { SummaryDialog } from './components/summaryDialog/summaryDialog'
import { PurchaseListFilters } from './components/purchaseListFilters/purchaseListFilters'
import { forceUpdate } from './utils/functions'

const rowRenderer = ({ rowData, cells }) => {
  if (rowData.content) {
    return <InnerTable orders={rowData.content} purchaseId={rowData.id} />
  }
  return cells
}

export const ListOrderContext = createContext<{
  orderToDetail: IOrder
  setOrderToDetail: React.Dispatch<(prevState: IOrder) => IOrder>
  selectedPurchaseOrders: Record<string, IOrder[]>
  setSelectedPurchaseOrders: React.Dispatch<React.SetStateAction<Record<string, IOrder[]>>>
}>(null)

const filterName = 'purchasesFilter'
const filterAtom = atomWithHashAndName(filterName, undefined)

export const ListOrders = ({ listSelector }: { listSelector: React.ReactNode }) => {
  const [orderToDetail, setOrderToDetail] = useState<IOrder>()
  const [selectedPurchaseOrders, setSelectedPurchaseOrders] = useState<Record<string, IOrder[]>>({})
  const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false)
  const persistentFilterWithHash = usePersistentFilterWithHash({ filterAtom, filterName })

  const { ordersList, fetchNextPage, isLoading, isFetching } = useOrderList({
    filter: persistentFilterWithHash.filter,
  })

  const { persist, value: persistedColumnsOptions } = usePersistentTableConfig({
    tableDefaultConfig: purchaseTableColumnsOptions,
    tableName: 'purchaseOrders',
  })
  const [actualColumnsOptions, setActualColumnsOptions] = useState<ColumnOption[]>(persistedColumnsOptions)

  const defaultColumns = useMemo(() => {
    return purchaseTableColumns({
      openColumnsConfiguration: () => setIsColumnSelectorOpen(true),
    })
  }, [])

  const columns = useMemo(() => {
    return organizeColumnsByOptions({
      columns: defaultColumns,
      options: actualColumnsOptions,
    })
  }, [defaultColumns, actualColumnsOptions])

  const changeColumnsHandler: ColumnsSelectorProps['onConfirm'] = ({ columnsOptions, shouldPersist }) => {
    if (shouldPersist) persist(columnsOptions)
    setActualColumnsOptions(columnsOptions)
    forceUpdate()
  }

  const contextValue = {
    orderToDetail,
    setOrderToDetail,
    selectedPurchaseOrders,
    setSelectedPurchaseOrders,
  }

  return (
    <ListOrderContext.Provider value={contextValue}>
      <Panel noPadding>
        <PageHeader pageTitle='Pedidos' />
        <Box
          sx={(theme) => ({
            '& .BaseTable__row--expanded': {
              boxShadow: 'inset 6px 0' + theme.palette.primary.light + '4D',
              transition: 'box-shadow .12s',
            },
            padding: '32px',
          })}
        >
          <Typography sx={{ marginBottom: '8px', fontWeight: '600' }}>
            Veja pedidos feitos pelas operadoras e empresas
          </Typography>
          <Typography
            variant='body2'
            sx={(theme) => ({ marginBottom: '16px', color: theme.palette.grey[700], fontWeight: 400 })}
          >
            Acompanhe, registre e tome ações sobre pedidos na listagem abaixo. Você pode configurar sua visualização
            através dos filtros, e também pela configuração das colunas.
          </Typography>
          <PurchaseListFilters persistentFilterWithHash={persistentFilterWithHash} />
          <Box
            sx={{
              width: 'fit-content',
              margin: '32px 0 32px auto',
            }}
          >
            {listSelector}
          </Box>
          <AutoTableVirtualized
            variant='report'
            expandColumnKey={columns[0].key}
            columns={columns}
            rows={ordersList}
            rowRenderer={rowRenderer}
            components={{
              ExpandIcon: ExpandIcon,
            }}
            isLoading={isLoading}
            isFetching={isFetching}
            onEndReachedThreshold={200}
            onEndReached={() => {
              fetchNextPage()
            }}
            estimatedRowHeight={64}
            //rowHeight={64}
            ignoreFunctionInColumnCompare={false}
            visibleRows={10}
          />
        </Box>
        <ColumnsSelector
          open={isColumnSelectorOpen}
          columnsOptions={actualColumnsOptions}
          onClose={() => setIsColumnSelectorOpen(false)}
          onConfirm={changeColumnsHandler}
          defaultColumnsOptions={purchaseTableColumnsOptions}
        />
      </Panel>
      {Boolean(orderToDetail) && (
        <SummaryDialog open={Boolean(orderToDetail)} order={orderToDetail} onClose={() => setOrderToDetail(null)} />
      )}
    </ListOrderContext.Provider>
  )
}
