import styled from '@emotion/styled'
import { Button } from '@stationkim/front-ui'

export const Container = styled('div')`
  margin: 16px 0;
`

export const Plan = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  padding: 16px;
  gap: 16px;
  border-radius: 4px;
  background-color: rgba(25, 118, 210, 0.1);

  p {
    font-size: 20px;
    font-weight: 500;
  }

  svg {
    min-width: 20px;
    width: 20px;
    min-height: 20px;
    height: 20px;
  }
`

export const SelectPlan = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
  border-color: rgba(211, 47, 47, 1);
  color: rgba(211, 47, 47, 1);

  :hover {
    background: rgba(211, 47, 47, 0.1);
    border-color: rgba(211, 47, 47, 1);
    color: rgba(211, 47, 47, 1);
  }
`

export const ContainerPlan = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;

  button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: transparent;
    font-size: 18px;
    font-weight: 500;
    color: rgba(21, 101, 192, 1);
    border: 0;
    cursor: pointer;
  }
`
