import api from '@/infra/api'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { CompanyGeneralDataContext } from '../../../companyGeneralData'

export const usePlan = ({ onClose }: { onClose: () => void }) => {
  const notifications = useNotifications()
  const queryClient = useQueryClient()

  const { companyId } = useContext(CompanyGeneralDataContext)

  const { mutate: selectPlan } = useMutation({
    mutationFn: (payload: { NameProfile: string }) =>
      api.patch(`/resale-bff/companies/select-plan`, { ...payload, CompanyId: companyId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['company', companyId] })
      notifications.push({ content: 'Plano selecionado com sucesso!', type: EMessageType.Success })
      onClose()
    },
  })

  return {
    selectPlan,
  }
}
