/* eslint-disable no-constant-condition */
import styled from '@emotion/styled'
import { Button } from '@stationkim/front-ui'

export const Container = styled('div')`
  width: 760px;
  height: 783.9px;
  padding: 32px;
  background-color: #fff;
`

export const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;

  p {
    font-size: 20px;
    font-weight: 500;
    color: rgba(33, 33, 33, 1);
  }

  svg {
    cursor: pointer;
  }
`

export const Description = styled('p')`
  font-size: 18px;
  font-weight: 400;
  color: rgba(97, 97, 97, 1);
  max-width: 560px;
`

export const Plan = styled('div')<{ active: boolean }>`
  position: relative;
  width: 332px;
  height: 495.9px;
  padding: 16px;
  gap: 24px;
  border-radius: 4px;
  border: 1px solid ${({ active }) => (active ? 'rgba(25, 118, 210, 1)' : 'rgba(224, 227, 231, 1)')};

  p {
    font-size: 20px;
    font-weight: 500;
    padding: 16px 0 24px;
    border-bottom: 1px solid rgba(224, 227, 231, 1);
    margin-bottom: 18px;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: rgba(33, 33, 33, 1);
  }
`

export const Topics = styled('ul')`
  li {
    font-size: 14px;
    font-weight: 400;
    color: rgba(97, 97, 97, 1);
    margin: 14px 0;
    list-style: initial;
    list-style-position: inside;
  }
`

export const SelectPlan = styled(Button)<{ active: boolean }>`
  position: absolute;
  bottom: 16px;
  width: 300px;
  background-color: ${({ active }) => (active ? 'rgba(25, 118, 210, 1)' : '')};

  ${({ active }) =>
    active
      ? `
    &:hover {
      opacity: 0.8;
      background-color: rgba(25, 118, 210, 1);
    }
  `
      : null}
`

export const ContainerPlan = styled('div')`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-top: 32px;
`

export const ContainerActions = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-around;
  margin-top: 50px;

  button {
    width: 300px;

    &:first-child {
      border: 1px solid rgba(97, 97, 97, 1);
      color: rgba(97, 97, 97, 1);

      :hover {
        border: 1px solid rgba(97, 97, 97, 1);
        color: rgba(97, 97, 97, 1);
      }
    }
  }
`
