export const BasicPlanIcon = () => {
  return (
    <svg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.5312 9.40625C21.1172 10.0312 21.1172 11.0078 20.5312 11.6328L12.0938 20.0703C11.4688 20.6562 10.4922 20.6562 9.86719 20.0703L1.42969 11.6328C0.84375 11.0078 0.84375 10.0312 1.42969 9.40625L9.86719 0.96875C10.4922 0.382812 11.4688 0.382812 12.0938 0.96875L20.5312 9.40625Z'
        fill='#1565C0'
      />
    </svg>
  )
}
