import { useState } from 'react'
import { faLink, faLinkSlash } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Icon,
  Typography,
} from '@stationkim/front-ui'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'
import { IOrder } from '../../../../hooks/useOrderList'
import { useOrderAttribution } from '../../../../hooks/useOrderAttribution'
import { AttributionMenuContainer, AttributionMenuItems } from './styles'

interface AttributionMenuProps {
  open: boolean
  orderCount: number
  ordersList: IOrder[]
  purchaseId: string
  onRemove: () => void
  onCancel: () => void
  onAttribution: () => void
}

export const AttributionMenu = ({
  open,
  onRemove,
  onCancel,
  onAttribution,
  ordersList = [],
  orderCount,
  purchaseId,
}: AttributionMenuProps) => {
  const counterText = orderCount > 1 ? ' pedidos selecionados' : ' pedido selecionado'
  const notification = useNotifications()
  const [removalContribution, setRemovalContribution] = useState(false)

  const selectedOrdersId = ordersList.map((order) => order.id)

  const { changeAttribution, isLoading } = useOrderAttribution()

  const handleChangeAttribution = (shouldAttribute: boolean, onSuccess: () => void) => {
    changeAttribution.mutate(
      { attribution: shouldAttribute, ordersIds: selectedOrdersId, purchaseId },
      {
        onSuccess: () => {
          onSuccess()
          notification.push({
            content: `Atribuição ${shouldAttribute ? 'efetuada' : 'removida'} com sucesso!`,
            type: EMessageType.Success,
          })
        },
      },
    )
  }

  const hasAttribution = ordersList.some((order) => order.userAttributed.length > 0)

  return (
    <AttributionMenuContainer open={open || isLoading}>
      <AttributionMenuItems open={open || isLoading}>
        {isLoading ? (
          <CircularProgress size='22px' sx={{ color: 'white', margin: 'auto' }} />
        ) : (
          <>
            <Typography>{orderCount + counterText}</Typography>
            <Button
              size='small'
              startIcon={<Icon icon={faLink} />}
              onClick={() => handleChangeAttribution(true, onAttribution)}
            >
              Atribuir a mim
            </Button>
            <Button
              size='small'
              startIcon={<Icon icon={faLinkSlash} />}
              onClick={() => setRemovalContribution(true)}
              disabled={!hasAttribution}
            >
              Remover atribuições
            </Button>
            <Divider orientation='vertical' variant='middle' flexItem />
            <Button size='small' onClick={onCancel}>
              CANCELAR SELEÇÃO
            </Button>
          </>
        )}
      </AttributionMenuItems>
      <Dialog open={removalContribution}>
        <DialogTitle>Retirar atribuição de responsável?</DialogTitle>
        <DialogContent sx={{ margin: '16px 0 32px 0' }}>
          <Typography>
            Essa ação não poderá ser desfeita. Cada usuário do
            <br />
            sistema é responsável pela sua própria atribuição.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            sx={{ filter: 'grayscale(100%)' }}
            fullWidth
            onClick={() => setRemovalContribution(false)}
          >
            Cancelar
          </Button>
          <Button
            variant='contained'
            color='error'
            fullWidth
            onClick={() => {
              handleChangeAttribution(false, onRemove)
              setRemovalContribution(false)
            }}
          >
            Retirar
          </Button>
        </DialogActions>
      </Dialog>
    </AttributionMenuContainer>
  )
}
