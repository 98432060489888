import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  Typography,
} from '@/presentation/components/atoms'
import { FileUploadCore } from '@/presentation/components/molecules'
import { Accept } from 'react-dropzone-esm'
import { Controller, useForm } from 'react-hook-form'

interface UploadDialogProps {
  isOpen: boolean
  onClose: () => void
  onConfirm?: (data: any) => void
  title?: string
  subtitle?: string
  initialValue?: null | File
  accept?: Accept
  helpText?: string
  isLoading?: boolean
}

export const UploadDialog = ({
  title = 'Faça o upload do arquivo',
  subtitle,
  isOpen,
  onClose,
  onConfirm,
  initialValue,
  accept,
  helpText,
  isLoading,
}: UploadDialogProps) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: { files: initialValue },
  })
  const onSubmit = (data) => {
    const formData = new FormData()
    formData.append('files', data.files)
    onConfirm && onConfirm(formData)
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      {subtitle && <Typography sx={{ marginTop: '16px' }}>{subtitle}</Typography>}
      <DialogContent sx={{ margin: '32px 0' }}>
        <form onSubmit={handleSubmit(onSubmit)} id='upload-dialog-form'>
          {isLoading ? (
            <Skeleton variant='rounded' width='100%' height='140px' />
          ) : (
            <Controller
              name='files'
              control={control}
              render={({ field: { onChange, value } }) => (
                <FileUploadCore
                  onChange={onChange}
                  value={value}
                  accept={accept}
                  sx={{ minWidth: { xs: 'initial', md: '420px' } }}
                />
              )}
            />
          )}
        </form>
        {helpText && (
          <Typography variant='label3' sx={{ marginTop: '16px' }}>
            {helpText}
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', gap: '16px', flexDirection: { xs: 'column-reverse', sm: 'row' } }}>
        <Button variant='outlined' onClick={onClose} fullWidth disabled={isLoading}>
          Cancelar
        </Button>
        <Button
          variant='contained'
          fullWidth
          sx={{ marginLeft: '0 !important' }}
          type='submit'
          form='upload-dialog-form'
          disabled={!isDirty || isLoading}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
