import { AvatarGroup, Box } from '@stationkim/front-ui'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SelectionMenu } from './components/selectionMenu/selectionMenu'
import { StyledAvatar } from './styles'
import { IUserProfile } from '@/main/interfaces'

export type TUser = IUserProfile & { notificationCount: number }

export interface UserAttributionFilterProps {
  users: TUser[]
  onChange: (e) => void
  initialValue?: string[]
}

const userClick = (userId: string, selected: string[]) => {
  const userIndex = selected.indexOf(userId)
  if (userIndex < 0) return [...selected, userId]
  const newSelected = [...selected]
  newSelected.splice(userIndex, 1)
  return newSelected
}

export const UserAttributionFilter = ({ users, onChange, initialValue = [] }: UserAttributionFilterProps) => {
  const [selectedUsers, setSelectedUsers] = useState<string[]>(initialValue)
  const [maxAvatars, setMaxAvatars] = useState<null | number>(null)
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(menuAnchor)
  const containerRef = useRef(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget)
  }

  const renderUsersAvatars = useCallback(
    (usersToRender: TUser[]) => {
      if (!usersToRender) return []
      return usersToRender?.map((user) => (
        <StyledAvatar
          key={user.id}
          name={user.name}
          src={user.image}
          notificationCount={user.notificationCount}
          sx={{ cursor: 'pointer' }}
          active={selectedUsers.includes(user.id)}
          onClick={() => {
            setSelectedUsers((selected) => {
              const newDate = userClick(user.id, selected)
              onChange(newDate)
              return newDate
            })
          }}
        />
      ))
    },
    [selectedUsers, onChange],
  )

  const usersToRender = renderUsersAvatars(users) //useMemo(, [users, renderUsersAvatars])

  const setVisibleAvatars = () => {
    if (containerRef.current) {
      const maxAvatars = 5
      setMaxAvatars(maxAvatars)
    }
  }

  const resizeObserver = useMemo(() => {
    return new ResizeObserver(() => {
      setVisibleAvatars()
    })
  }, [])

  useEffect(() => {
    resizeObserver.observe(containerRef.current)
  }, [resizeObserver])

  useEffect(() => {
    if (!isMenuOpen) {
      onChange(selectedUsers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen])

  const newUsers = users?.filter((user) => user?.id !== '00000000-0000-0000-0000-000000000000')

  return (
    <Box ref={containerRef} onResize={setVisibleAvatars}>
      <AvatarGroup
        total={newUsers.length}
        max={maxAvatars}
        slotProps={{
          additionalAvatar: {
            onClick: handleClick as any,
            sx: { cursor: 'pointer' },
          },
        }}
      >
        {usersToRender}
      </AvatarGroup>
      <SelectionMenu
        anchorEl={menuAnchor}
        open={isMenuOpen}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        users={users}
        onClose={() => {
          setMenuAnchor(null)
        }}
      />
    </Box>
  )
}
