import { AxiosResponse } from 'axios'
import { useAtomValue } from 'jotai'
import { useInfiniteQuery } from '@tanstack/react-query'
import { decode } from 'jsonwebtoken'
import api from '@/infra/api'
import { EStatusPedido, ETipoPagamento, ETipoPedido, ETipoPendencia } from '@/main/enums'
import { AccountPersistentStore } from '@/main/store'
import { IStandardResponse, IUserProfile } from '@/main/interfaces'
import { IPurchasesApiResponse } from './iPurchasesApiResponse'
import { organizePurchasesData } from './utils/organizePurchaseData'
import { usePersistentFilterWithHash } from '@stationkim/front-ui'
import qs from 'qs'

export enum EOrderPendency {
  None,
  Pending,
  Resolved,
}

export type TOrderPendency = {
  resolutionDate: string
  type: ETipoPendencia
  resolved: boolean
}

export interface IOrder {
  id: string
  orderNumber: string | number
  operator: string
  totalValue: number
  orderStatus: EStatusPedido
  pendencies: TOrderPendency[] | null
  pendencyStatus?: EOrderPendency
  totalItensInOrder: number
  orderDate: string
  creditDate?: string
  operatorSite: string
  operatorEmail: string
  operatorUser: string
  operatorPassword: string
  companyId: string
  orderType: ETipoPedido
  purchaseId: string
  userAttributed: IUserProfile[]
  useBalanceProjection: boolean
}

export interface IPurchase {
  id: string
  purchaseNumber: string
  companyTradeName: string
  purchaseDate: string
  purchaseOrdersTypes: ETipoPedido[]
  purchaseValue: number
  purchaseComplementaryValue: number
  purchaseStatus: number
  clientCode: number
  purchaseRechargeValue: number
  purchaseFeesTotal: number
  situation: string // revalidate
  children: {
    id: string
    content: IOrder[]
  }[]
  purchaseEndDate?: string
  responsibleUser?: string
  pendencyStatus?: EOrderPendency
  creditDate?: string
  slipDueDate?: string
  paymentMethod?: ETipoPagamento[]
  usersAttributed: IUserProfile[]
}

interface IUseOrderList {
  ordersList: IPurchase[]
  isLoading: boolean
  isFetching: boolean
  fetchNextPage: () => void
}

export interface IPurchaseOrdersResponse extends IStandardResponse<IPurchasesApiResponse> {}

export const useOrderList = ({
  filter,
}: {
  filter: ReturnType<typeof usePersistentFilterWithHash>['filter']
}): IUseOrderList => {
  const account = useAtomValue(AccountPersistentStore)
  const resaleId = decode(account.auth.accessToken)?.resaleId
  const { data, fetchNextPage, isFetching, isLoading } = useInfiniteQuery<AxiosResponse<IPurchaseOrdersResponse>>({
    queryKey: ['purchases', resaleId, filter],
    queryFn: ({ pageParam = 1 }) =>
      api.get(
        `revenda-pedidos-bff/compra?idrevenda=${resaleId}&ordenarpor=NumeroPedido&ordenardecrescente=True&NumeroPagina=${pageParam}&TamanhoPagina=12`,
        {
          params: filter,
          paramsSerializer: (params) => {
            const newParams = { ...params }
            for (const key in newParams) {
              if ((!newParams[key] || newParams[key]?.length === 0) && newParams[key] !== 0) delete newParams[key]
            }
            return qs.stringify(newParams)
          },
        },
      ),
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage) => {
      if (lastPage) {
        const nextPageNumber = lastPage.data?.valor?.pageNumber + 1
        const pagesTotal = lastPage.data?.valor?.pageCount
        if (nextPageNumber > pagesTotal) return
        return nextPageNumber
      }
      return
    },
  })

  const fetchMore = () => {
    if (!isFetching) fetchNextPage()
  }

  return {
    ordersList: data ? organizePurchasesData(data.pages) : [],
    fetchNextPage: fetchMore,
    isFetching,
    isLoading,
  }
}
