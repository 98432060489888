import { MenuProps } from '@mui/material'
import { Divider, Menu } from '@stationkim/front-ui'
import { TUser } from '../../userAttributionFilter'
import { MenuOption } from '../menuOption/menuOption'

interface SelectionMenuProps extends MenuProps {
  users: TUser[]
  selectedUsers: string[]
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>
}

const handleOnCheck = ({
  user,
  selectedUsers,
  setSelectedUsers,
}: {
  user: TUser
  selectedUsers: TUser['id'][]
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>
}) => {
  const indexOfUser = selectedUsers.indexOf(user.id)
  if (indexOfUser < 0) {
    setSelectedUsers((selected) => [...selected, user.id])
    return
  }
  setSelectedUsers((selected) => {
    const newList = [...selected]
    newList.splice(indexOfUser, 1)
    return newList
  })
}

const handleNoReservationCheck = ({
  usersWithoutReservation,
  setSelectedUsers,
  isWithoutReservationChecked,
}: {
  usersWithoutReservation: TUser['id'][]
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>
  isWithoutReservationChecked: boolean
}) => {
  if (isWithoutReservationChecked)
    setSelectedUsers((selected) =>
      selected.filter((selectedUserId) => !usersWithoutReservation.includes(selectedUserId)),
    )
  else {
    setSelectedUsers((selected) => {
      const newList = [...selected]
      usersWithoutReservation.forEach((userWithoutId) => {
        if (!newList.includes(userWithoutId)) newList.push(userWithoutId)
      })
      return newList
    })
  }
}

export const SelectionMenu = ({
  open,
  anchorEl,
  users,
  selectedUsers,
  onClose,
  setSelectedUsers,
}: SelectionMenuProps) => {
  const usersWithoutReservation = users.filter((user) => {
    return user.id === '00000000-0000-0000-0000-000000000000'
  }, [])

  const isWithoutReservationChecked =
    usersWithoutReservation?.[0]?.notificationCount < 1
      ? false
      : selectedUsers.includes(usersWithoutReservation?.[0]?.id)

  const renderMenuOptions = () => {
    if (!open) return null
    return users
      ?.filter((user) => user?.id !== '00000000-0000-0000-0000-000000000000')
      .map((user) => (
        <MenuOption
          key={user.id}
          user={user}
          checked={selectedUsers.includes(user.id)}
          onCheck={(user) => {
            handleOnCheck({ user, selectedUsers, setSelectedUsers })
          }}
        />
      ))
  }

  return (
    <Menu open={open} anchorEl={anchorEl} slotProps={{ paper: { sx: { padding: '16px' } } }} onClose={onClose}>
      <MenuOption
        firstItem
        checked={isWithoutReservationChecked}
        onCheck={() =>
          handleNoReservationCheck({
            isWithoutReservationChecked,
            setSelectedUsers,
            usersWithoutReservation: [usersWithoutReservation?.[0]?.id],
          })
        }
        user={{
          id: '',
          name: 'Sem Responsáveis',
          notificationCount: usersWithoutReservation[0]?.notificationCount,
          image: null,
        }}
      />
      <Divider sx={{ margin: '16px 0' }} />
      {renderMenuOptions()}
    </Menu>
  )
}
