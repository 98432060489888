export const PremiumPlanIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.0625 0.205078C9.64844 -0.0683594 10.3125 -0.0683594 10.8984 0.205078L19.4531 4.15039C19.7656 4.30664 20 4.61914 20 4.9707C20 5.36133 19.7656 5.67383 19.4531 5.83008L10.8984 9.77539C10.3125 10.0488 9.64844 10.0488 9.0625 9.77539L0.507812 5.83008C0.195312 5.67383 0 5.36133 0 4.9707C0 4.61914 0.195312 4.30664 0.507812 4.15039L9.0625 0.205078Z'
        fill='#1565C0'
      />
      <path
        d='M20 9.9707C20 9.61914 19.7656 9.30664 19.4531 9.15039L17.3437 8.17383L11.4062 10.9082C10.5078 11.3379 9.45312 11.3379 8.55469 10.9082L2.61719 8.17383L0.507812 9.15039C0.195312 9.30664 0 9.61914 0 9.9707C0 10.3613 0.195312 10.6738 0.507812 10.8301L9.0625 14.7754C9.64844 15.0488 10.3125 15.0488 10.8984 14.7754L19.4531 10.8301C19.7656 10.6738 20 10.3613 20 9.9707Z'
        fill='white'
      />
      <path
        d='M20 9.9707C20 9.61914 19.7656 9.30664 19.4531 9.15039L17.3437 8.17383L11.4062 10.9082C10.5078 11.3379 9.45312 11.3379 8.55469 10.9082L2.61719 8.17383L0.507812 9.15039C0.195312 9.30664 0 9.61914 0 9.9707C0 10.3613 0.195312 10.6738 0.507812 10.8301L9.0625 14.7754C9.64844 15.0488 10.3125 15.0488 10.8984 14.7754L19.4531 10.8301C19.7656 10.6738 20 10.3613 20 9.9707Z'
        fill='#1976D2'
        fillOpacity='0.75'
      />
      <path
        d='M20 14.894C20 14.5425 19.7656 14.23 19.4531 14.0737L17.3437 13.0972L11.4062 15.8315C10.5078 16.2612 9.45312 16.2612 8.55469 15.8315L2.61719 13.0972L0.507812 14.0737C0.195312 14.23 0 14.5425 0 14.894C0 15.2847 0.195312 15.5972 0.507812 15.7534L9.0625 19.6987C9.64844 19.9722 10.3125 19.9722 10.8984 19.6987L19.4531 15.7534C19.7656 15.5972 20 15.2847 20 14.894Z'
        fill='white'
      />
      <path
        d='M20 14.894C20 14.5425 19.7656 14.23 19.4531 14.0737L17.3437 13.0972L11.4062 15.8315C10.5078 16.2612 9.45312 16.2612 8.55469 15.8315L2.61719 13.0972L0.507812 14.0737C0.195312 14.23 0 14.5425 0 14.894C0 15.2847 0.195312 15.5972 0.507812 15.7534L9.0625 19.6987C9.64844 19.9722 10.3125 19.9722 10.8984 19.6987L19.4531 15.7534C19.7656 15.5972 20 15.2847 20 14.894Z'
        fill='#1976D2'
        fillOpacity='0.4'
      />
    </svg>
  )
}
