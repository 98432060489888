import { styled } from '@mui/material'
import { Icon } from '@stationkim/front-ui'

export const ActionIcon = styled(Icon, {
  shouldForwardProp: (propName: string) => !['disabled', 'color'].includes(propName),
})<{ disabled?: boolean; color?: string }>(({ theme, disabled, color }) => ({
  height: '20px',
  width: '20px',
  color: disabled ? theme.palette.grey[500] : color,
  cursor: disabled ? 'initial' : 'pointer',
  padding: '8px',
  transition: 'all .15s linear',
  '&:hover': disabled
    ? null
    : {
        boxShadow: theme.shadows[4],
        transform: 'scale(1.05)',
        background: theme.palette.common.white,
      },
  '&:active': disabled
    ? null
    : {
        transform: 'scale(.95)',
      },
}))
