import axios from 'axios'
import qs from 'qs'
import { AccountModel } from '@/main/store'
import { dispatchAtomWithStorageObserverChangeEvent } from '@/main/hooks'

const cancelTokens = {}

function createCancelToken(endpoint: string) {
  if (cancelTokens[endpoint]) {
    cancelTokens[endpoint].cancel('Request canceled due to new request.')
  }

  cancelTokens[endpoint] = axios.CancelToken.source()
  return cancelTokens[endpoint].token
}

const refreshToken = async () => {
  const account: AccountModel = JSON.parse(localStorage.getItem('account'))
  const refreshToken = account?.auth?.refreshToken
  const site = account?.resale?.site
  try {
    if (!refreshToken) throw Error
    const res = await axios.post<AccountModel>(process.env.REACT_APP_API_HOST + '/resale-bff/login/refresh', {
      refreshToken,
      site,
    })
    const account = Object.assign(res?.data)
    const theme = JSON.parse(localStorage.getItem('globalTheme'))
    localStorage.setItem('globalTheme', JSON.stringify({ ...theme, ...account.resale.theme }))
    localStorage.setItem('account', JSON.stringify(account))
    dispatchAtomWithStorageObserverChangeEvent({ storageName: 'globalTheme' })
    return account
  } catch (error) {
    localStorage.setItem('account', JSON.stringify({ ...account, auth: null }))
    return false
  } finally {
    dispatchAtomWithStorageObserverChangeEvent({ storageName: 'account' })
  }
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  paramsSerializer: (params) => {
    const newParams = { ...params }
    for (const key in newParams) {
      if ((!newParams[key] || newParams[key]?.length === 0) && newParams[key] !== 0) delete newParams[key]
      if (Array.isArray(newParams[key])) newParams[key] = `[${newParams[key]}]`
    }

    return qs.stringify(newParams)
  },
})

api.interceptors.request.use(async (config) => {
  let account: AccountModel = JSON.parse(localStorage.getItem('account'))
  config.cancelToken = createCancelToken(config.url.split('?')[0])
  const now = new Date()
  const tokenExpirationDate = new Date(account?.auth?.expiresIn)
  if (now > tokenExpirationDate) {
    await refreshToken()
    account = JSON.parse(localStorage.getItem('account'))
  }

  if (account?.auth?.accessToken) config.headers.common.Authorization = `Bearer ${account.auth.accessToken}`
  else delete config.headers.common.Authorization
  return config
})

api.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const account = await refreshToken()
      originalRequest.headers.Authorization = `Bearer ${account.auth.accessToken}`
      return api(originalRequest)
    }
    return Promise.reject(error)
  },
)

export default api
