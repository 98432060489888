import { Title, DropContainer, DocumentIcon, SubTitle, TitleWrapper } from './styles'
import RDropzone from 'react-dropzone-esm'
import Progress from '../progress'
import fileSize from 'filesize'
import AddDocument from '@/presentation/assets/icons/addDocument.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'

const Dropline2 = ({
  cancelUpload,
  children,
  error,
  exclude,
  formatsLabel,
  maxSize,
  onDrop,
  setError,
  subTitle = 'Arraste e solte o arquivo aqui',
  title = 'Envie um novo arquivo',
  uploadingFile,
  ...props
}) => {
  const errorMessages = {
    'file-invalid-type': `Apenas arquivos nos formatos ${formatsLabel} são aceitos.`,
    'file-too-large': `Arquivo limitado a ${fileSize(maxSize)}`,
  }

  return (
    <RDropzone
      maxSize={maxSize}
      onDrop={onDrop}
      onDropRejected={(e) => setError && setError(errorMessages[e[0].errors[0].code])}
      {...props}
    >
      {({ getRootProps, getInputProps }) => (
        <DropContainer filename={uploadingFile?.name} error={error} {...getRootProps()}>
          {!uploadingFile?.uploading ? (
            <>
              <DocumentIcon src={AddDocument} alt='icone de adição de arquivo' hasfile={!!uploadingFile?.name} />
              <TitleWrapper>
                <Title>{title}</Title>
                <SubTitle>
                  {uploadingFile?.name ? (
                    <>
                      <strong>{uploadingFile?.name}</strong> adicionado com sucesso{' '}
                    </>
                  ) : (
                    subTitle
                  )}
                </SubTitle>
              </TitleWrapper>
              {uploadingFile?.name && exclude && (
                <FontAwesomeIcon
                  icon={faTrashCan}
                  size='xl'
                  onClick={(e) => {
                    e.stopPropagation()
                    exclude && exclude()
                  }}
                />
              )}
            </>
          ) : (
            <Progress uploadingFile={uploadingFile} onCancel={cancelUpload} />
          )}
          <input {...getInputProps()} />
        </DropContainer>
      )}
    </RDropzone>
  )
}

export default Dropline2
