import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FileUpload,
  Skeleton,
  Typography,
} from '@stationkim/front-ui'
import { useState } from 'react'

interface SlipUploadDialogProps {
  onClose: () => void
  onConfirm: (file: any) => void
  isLoading: boolean
}

export const SlipUploadDialog = ({ onClose, onConfirm, isLoading }: SlipUploadDialogProps) => {
  const [file, setFile] = useState<any>(null)

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Anexar boleto gerado</DialogTitle>
      <DialogContent>
        <Typography variant='body2' sx={{ margin: '16px 0' }}>
          Anexe o boleto para que o financeiro efetue o pagamento.
        </Typography>
        {isLoading ? (
          <Skeleton variant='rounded' height='156px' sx={{ marginBottom: '16px' }} />
        ) : (
          <FileUpload
            accept={{
              'application/pdf': ['.pdf'],
            }}
            onChange={setFile}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' sx={{ filter: 'grayscale(100)' }} onClick={onClose} fullWidth>
          Cancelar
        </Button>
        <Button variant='contained' onClick={() => onConfirm(file)} fullWidth disabled={!file}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
