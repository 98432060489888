import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

type TDownloadedLayouts = { employee: boolean; order: boolean }
const downloadOrderLayoutStorageAtom = atomWithStorage<Record<string, TDownloadedLayouts>>(
  'downloadedOrderLayoutStorage',
  {},
)

export const useDownloadedOrderLayoutStorage = ({ orderId }: { orderId: string }) => {
  const defaultDownloadLayouts: TDownloadedLayouts = { employee: false, order: false }
  const [value, setValue] = useAtom(downloadOrderLayoutStorageAtom)

  const updateDownloaded = (file: keyof TDownloadedLayouts) => {
    setValue((value) => {
      const newStorage = { ...value }
      const newValue = newStorage[orderId] ?? defaultDownloadLayouts
      newValue[file] = true
      return { ...newStorage, [orderId]: newValue }
    })
  }

  return {
    downloadedLayouts: value[orderId] || defaultDownloadLayouts,
    updateDownloaded,
  }
}
