import { useContext, useEffect, useState } from 'react'
import { formFieldsConfiguration } from '@/main/utils/formFieldsConfiguration/formFieldsConfiguration'
import { IUseCompanyData, useAddressesCodes, useAddressesInfos, useModifyCompany } from '@/main/hooks'
import { Form, FormArray, FormField, FormHeader } from '@/presentation/components/organisms'
import { CompanyGeneralDataContext } from '../../companyGeneralData'
import { EMessageType } from '@/main/store'
import { useForm } from 'react-hook-form'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'

export const AddressesForm = () => {
  const { resources } = useAccessProfile({ resource: 'empresa' })

  const { company, companyId, notification, queryClient, isLoadingCompany } = useContext(CompanyGeneralDataContext)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const form = useForm<any>({
    defaultValues: company,
    shouldFocusError: true,
    mode: 'onChange',
  })

  useEffect(() => {
    !isLoadingCompany &&
      form.reset({
        addresses: [company?.addresses[0]],
      })
  }, [isLoadingCompany])

  const onSuccess = () => {
    notification.push({ content: 'Endereço editado com sucesso!', type: EMessageType.Success })
    queryClient.invalidateQueries({ queryKey: ['company', companyId] })
    setIsEditing(false)
  }

  const modifyCompany = useModifyCompany({
    config: {
      addresses: { onSuccess },
      addAddress: { onSuccess },
    },
  })

  const [cepToSearch, setCepToSearch] = useState<{ cep: string; starterAddressName: string }>({
    cep: '',
    starterAddressName: '',
  })
  const { cepData, isLoading: isLoadingCepData } = useAddressesInfos({ cep: cepToSearch.cep })
  const { addressTypes, laneTypes, isLoading: isLoadingAddressesCodes } = useAddressesCodes()

  const isLoading =
    isLoadingCompany ||
    isLoadingCepData ||
    isLoadingAddressesCodes ||
    modifyCompany.addresses.isLoading ||
    modifyCompany.addAddress.isLoading

  useEffect(() => {
    if (cepData) {
      form.setValue(cepToSearch.starterAddressName + 'city', cepData.localidade)
      form.setValue(cepToSearch.starterAddressName + 'state', cepData.uf)
      form.setValue(cepToSearch.starterAddressName + 'laneName', cepData.logradouro)
      form.setValue(cepToSearch.starterAddressName + 'neighborhood', cepData.bairro)
      form.setValue(cepToSearch.starterAddressName + 'cityCode', cepData.cityCode)
    } else if (!cepData || cepData.erro) {
      form.setValue(cepToSearch.starterAddressName + 'city', '')
      form.setValue(cepToSearch.starterAddressName + 'state', '')
      form.setValue(cepToSearch.starterAddressName + 'laneName', '')
      form.setValue(cepToSearch.starterAddressName + 'neighborhood', '')
      form.setValue(cepToSearch.starterAddressName + 'cityCode', '')
      form.setValue(cepToSearch.starterAddressName + 'complement', '')
      form.setValue(cepToSearch.starterAddressName + 'number', '')
      form.setValue(cepToSearch.starterAddressName + 'name', '')
      form.setValue(cepToSearch.starterAddressName + 'addressCode', '')
      form.setValue(cepToSearch.starterAddressName + 'laneTypeCode', '')
    }
  }, [cepData, form])

  const handleAddressesSubmit = (data: IUseCompanyData) => {
    const dataToBeUsed = {
      id: data.addresses[0]?.id,
      cep: data.addresses[0]?.cep,
      state: data.addresses[0]?.state,
      city: data.addresses[0]?.city,
      neighborhood: data.addresses[0]?.neighborhood,
      laneName: data.addresses[0]?.laneName,
      number: data.addresses[0]?.number,
      complement: data.addresses[0]?.complement,
      name: data.addresses[0]?.name,
      addressCode: data.addresses[0]?.addressCode,
      laneTypeCode: data.addresses[0]?.laneTypeCode,
      cityCode: data.addresses[0]?.cityCode,
    }

    if (dataToBeUsed.id) modifyCompany?.addresses.mutate(dataToBeUsed)
    else {
      delete dataToBeUsed.id
      modifyCompany?.addAddress.mutate(dataToBeUsed)
    }
  }

  return (
    <Form
      form={form}
      onSubmit={(data) => handleAddressesSubmit(data)}
      isLoading={isLoading}
      isEditable={isEditing}
      onEditButtonClick={() => setIsEditing(true)}
      onCancel={() => setIsEditing(false)}
    >
      <FormHeader title='Endereço' hasPermission={resources({ item: 'empresa_gerenciar' })} />
      <FormArray
        name='addresses'
        maxAmount={1}
        render={({ indexName, fieldsValues }) => (
          <>
            <FormField
              type='maskedInput'
              name={indexName + 'cep'}
              gridProps={{ xs: 12, sm: 6, md: 2 }}
              {...formFieldsConfiguration.address.cep}
              rules={{
                validate: {
                  isValid: () => !(!cepData || cepData.erro) || 'Cep inválido',
                },
                ...formFieldsConfiguration.address.cep.rules({ isRequired: true }),
              }}
              onChange={(e: string) => {
                e?.length >= 8 && setCepToSearch({ cep: e, starterAddressName: indexName })
              }}
            />
            <FormField
              type='input'
              name={indexName + 'city'}
              gridProps={{ xs: 12, sm: 6, md: 2 }}
              label='Cidade'
              blocked={true}
            />
            <FormField
              type='input'
              name={indexName + 'state'}
              gridProps={{ xs: 12, sm: 6, md: 2, lg: 1 }}
              label='UF'
              blocked={true}
            />
            <FormField
              type='input'
              name={indexName + 'neighborhood'}
              gridProps={{ xs: 12, sm: 6, md: 2 }}
              {...formFieldsConfiguration.address.neighborhood}
              blocked={fieldsValues?.neighborhood !== ''}
              rules={formFieldsConfiguration.address.neighborhood.rules({ isRequired: true })}
              inputProps={{ maxLength: 50 }}
            />
            <FormField
              type='input'
              name={indexName + 'laneName'}
              gridProps={{ xs: 12, sm: 9, md: 4 }}
              {...formFieldsConfiguration.address.laneName}
              blocked={fieldsValues?.laneName !== ''}
              rules={formFieldsConfiguration.address.laneName.rules({ isRequired: true })}
              inputProps={{ maxLength: 120 }}
            />
            <FormField
              type='maskedInput'
              name={indexName + 'number'}
              gridProps={{ xs: 12, sm: 3, md: 2 }}
              {...formFieldsConfiguration.address.number}
              rules={formFieldsConfiguration.address.number.rules({ isRequired: true })}
              inputProps={{ maxLength: 5 }}
            />
            <FormField
              type='input'
              name={indexName + 'complement'}
              gridProps={{ xs: 12, sm: 6, md: 2 }}
              {...formFieldsConfiguration.address.complement}
              rules={formFieldsConfiguration.address.complement.rules()}
              inputProps={{ maxLength: 30 }}
            />
            <FormField
              type='input'
              name={indexName + 'name'}
              gridProps={{ xs: 12, sm: 6, md: 2 }}
              {...formFieldsConfiguration.address.name}
              rules={formFieldsConfiguration.address.name.rules({ isRequired: true })}
              inputProps={{ maxLength: 30 }}
            />
            <FormField
              type='select'
              name={indexName + 'addressCode'}
              gridProps={{ xs: 12, sm: 6, md: 2 }}
              label={'Tipo de endereço'}
              options={addressTypes}
              getOptionLabel={(option) => option.description}
              getOptionValue={(option) => option.code}
              rules={formFieldsConfiguration.address.addressCode.rules({ isRequired: true })}
            />
            <FormField
              type='select'
              name={indexName + 'laneTypeCode'}
              gridProps={{ xs: 12, sm: 6, md: 2 }}
              label={'Tipo de logradouro'}
              options={laneTypes}
              getOptionLabel={(option) => option.description}
              getOptionValue={(option) => option.code}
              rules={formFieldsConfiguration.address.laneTypeCode.rules({ isRequired: true })}
            />
          </>
        )}
      />
    </Form>
  )
}
