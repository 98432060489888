export const SelectedIcon = () => {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 10.4038C0 4.896 4.45312 0.403809 10 0.403809C15.5078 0.403809 20 4.896 20 10.4038C20 15.9507 15.5078 20.4038 10 20.4038C4.45312 20.4038 0 15.9507 0 10.4038ZM14.4922 8.68506C14.9219 8.25537 14.9219 7.59131 14.4922 7.16162C14.0625 6.73193 13.3984 6.73193 12.9688 7.16162L8.75 11.3804L6.99219 9.66162C6.5625 9.23193 5.89844 9.23193 5.46875 9.66162C5.03906 10.0913 5.03906 10.7554 5.46875 11.1851L7.96875 13.6851C8.39844 14.1147 9.0625 14.1147 9.49219 13.6851L14.4922 8.68506Z'
        fill='#FCFDFF'
      />
    </svg>
  )
}
