import { BasicPlanIcon } from '../assets/BasicPlanIcon'
import { PremiumPlanIcon } from '../assets/PremiumPlanIcon'

export const planIcon = ({ plan }: { plan: string }) => {
  switch (plan) {
    case 'Básico':
    case 'Plano Básico': {
      return <BasicPlanIcon />
    }
    case 'Premium':
    case 'Plano Premium': {
      return <PremiumPlanIcon />
    }
  }
}
