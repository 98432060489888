import { Box, styled } from '@mui/material'

export const AttributionMenuContainer = styled(Box)<{ open: boolean }>(({ open }) => ({
  overflow: 'hidden',
  position: 'relative',
  height: open ? '56px' : 0,
}))

export const AttributionMenuItems = styled(Box)<{ open: boolean }>(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  padding: '8px 16px 8px 16px',
  overflow: 'hidden',
  position: 'absolute',
  top: 0,
  left: open ? '0' : '100%',
  transition: 'all .1s ease-in-out',
  backgroundColor: theme.palette.primary.main,
  '& p': {
    color: theme.palette.common.white,
    fontSize: '.875rem',
    marginRight: 'auto',
  },
  '& button': {
    color: theme.palette.common.white,
    textDecoration: 'none',
    fontWeight: 400,
    marginLeft: '32px',
  },
  '& button:nth-of-type(2)': {
    marginRight: '32px',
  },
  '& button svg': {
    height: '13px',
    width: '17px',
  },
}))
