export const CloseIcon = ({ onClose }: { onClose: () => void }) => {
  return (
    <svg onClick={onClose} width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.1094 10.6406C13.6172 11.1094 13.6172 11.9297 13.1094 12.3984C12.875 12.6328 12.5625 12.75 12.25 12.75C11.8984 12.75 11.5859 12.6328 11.3516 12.3984L7.25 8.29688L3.10938 12.3984C2.875 12.6328 2.5625 12.75 2.25 12.75C1.89844 12.75 1.58594 12.6328 1.35156 12.3984C0.84375 11.9297 0.84375 11.1094 1.35156 10.6406L5.45312 6.5L1.35156 2.39844C0.84375 1.92969 0.84375 1.10938 1.35156 0.640625C1.82031 0.132812 2.64062 0.132812 3.10938 0.640625L7.25 4.74219L11.3516 0.640625C11.8203 0.132812 12.6406 0.132812 13.1094 0.640625C13.6172 1.10938 13.6172 1.92969 13.1094 2.39844L9.00781 6.53906L13.1094 10.6406Z'
        fill='#616161'
      />
    </svg>
  )
}
