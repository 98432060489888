import { Theme } from '@mui/material'
import { Box, Button, ButtonProps, Tooltip, Typography } from '@stationkim/front-ui'

const resolvePendencyBaseText = 'Para resolver a pendência, baixe o arquivo'
interface ResolveButtonProps extends ButtonProps {}

const ResolveButton = ({ ...props }: ResolveButtonProps) => {
  return (
    <Button
      {...props}
      disableTouchRipple
      sx={(theme: Theme) => ({
        fontSize: '.75rem',
        lineHeight: '24px',
        cursor: 'pointer',
        alignItems: 'center',
        display: 'flex',
        textDecoration: 'underline',
        padding: '6px 0',
        height: 'unset',
        gap: '8px',
        fontWeight: '600',
        color: theme.palette.primary.main,
      })}
    >
      Pendência Resolvida?
    </Button>
  )
}

const Resolved = ({ id, children }: { id: string; children: React.ReactNode }) => {
  return (
    <Box sx={{ padding: '8px 0' }} id={id}>
      <Typography sx={{ fontSize: '.75rem', lineHeight: '.75rem' }}>Resolvido em:</Typography>
      <Typography sx={{ fontSize: '.75rem', lineHeight: '.75rem' }}>{children}</Typography>
    </Box>
  )
}

export interface PendenciesProps extends ResolveButtonProps {
  disabled?: boolean
  showTooltip?: boolean
  resolveDate?: string
  fileName: string
  onResolve: () => void
  id?: string
}

export const Pendency = ({ id, disabled, fileName, showTooltip, onResolve, resolveDate = '' }: PendenciesProps) => {
  return (
    <Tooltip
      title={
        showTooltip && (
          <>
            {resolvePendencyBaseText}
            <Box sx={(theme) => ({ color: theme.palette.primary.main })}>{fileName}.</Box>
          </>
        )
      }
    >
      <Box>
        {resolveDate ? (
          <Resolved id={id + '_label_date'}>{resolveDate}</Resolved>
        ) : (
          <ResolveButton id={id + '_button_resolve'} disabled={disabled} onClick={onResolve} />
        )}
      </Box>
    </Tooltip>
  )
}
