import { faColumns } from '@fortawesome/free-solid-svg-icons'
import { Theme } from '@mui/material'
import { Avatar, AvatarGroup, Box, Button, formatMoney, Icon, Tooltip } from '@stationkim/front-ui'
import { StatusChip } from '../components/statusChip/statusChip'
import { OrderType } from '../components/orderType/orderType'
import { PaymentType } from '../components/paymentType/paymentType'
import { ETipoPedido } from '@/main/enums'
import { PendencyChip } from '../components/pendencyChip/pendencyChip'

export const purchaseTableColumns = ({ openColumnsConfiguration }: { openColumnsConfiguration: () => void }) => {
  return [
    { title: 'Número da compra', dataKey: 'purchaseNumber', key: 'purchaseNumber', width: 180 },
    { title: 'Empresa', dataKey: 'companyTradeName', key: 'companyTradeName' },
    { title: 'Data da compra', dataKey: 'purchaseDate', key: 'purchaseDate' },
    {
      title: 'Tipo',
      dataKey: 'purchaseOrdersTypes',
      key: 'purchaseOrdersTypes',
      width: 190,
      cellRenderer: ({ rowData }) => {
        const typesInOrder = rowData.purchaseOrdersTypes?.reduce((final: ETipoPedido[], actual: ETipoPedido) => {
          if (final.includes(actual)) return final
          return [...final, actual]
        }, [])
        return (
          <Box sx={{ maxWidth: '100%' }}>
            {typesInOrder?.map((type, index) => (
              <OrderType type={type} key={`${rowData.id}_type_${index}`} />
            ))}
          </Box>
        )
      },
    },
    { title: 'Data do crédito', dataKey: 'creditDate', key: 'creditDate' },
    {
      title: 'Pendência',
      dataKey: 'pendency',
      key: 'pendency',
      cellRenderer: ({ rowData }) => {
        return <PendencyChip pendency={rowData.pendencyStatus} />
      },
    },
    {
      title: 'Responsável',
      dataKey: 'responsibleUser',
      key: 'responsibleUser',
      align: 'center',
      cellRenderer: ({ rowData }) => {
        return (
          <AvatarGroup sx={{ position: 'absolute' }}>
            {rowData.usersAttributed?.map((user) => {
              return (
                <Avatar
                  key={user.id}
                  name={String(user.name).toUpperCase()}
                  src={user.image}
                  sx={{ height: '32px', width: '32px', fontSize: '14px' }}
                />
              )
            })}
          </AvatarGroup>
        )
      },
    },
    {
      title: 'Status',
      dataKey: 'purchaseStatus',
      key: 'purchaseStatus',
      cellRenderer: ({ rowData }) => <StatusChip purchaseStatus={rowData.purchaseStatus} />,
    },
    {
      title: 'Valor do pedido',
      dataKey: 'purchaseValue',
      key: 'purchaseValue',
      width: 152,
      cellRenderer: ({ rowData }) => 'R$ ' + formatMoney(rowData.purchaseValue),
    },
    { title: 'Código do cliente', dataKey: 'clientCode', key: 'clientCode', width: 165 },
    { title: 'Data liquidação pedido', dataKey: 'purchaseEndDate', key: 'purchaseEndDate', width: 210 },
    {
      title: 'Valor recarga',
      dataKey: 'purchaseRechargeValue',
      key: 'purchaseRechargeValue',
      cellRenderer: ({ rowData }) => 'R$ ' + formatMoney(rowData.purchaseRechargeValue),
    },
    { title: 'Saldo projetado', dataKey: 'projectedBalance', key: 'projectedBalance' },
    {
      title: 'Valor complementar',
      dataKey: 'purchaseComplementaryValue',
      key: 'purchaseComplementaryValue',
      cellRenderer: ({ rowData }) => 'R$ ' + formatMoney(rowData.purchaseComplementaryValue),
    },
    {
      title: 'Total taxas',
      dataKey: 'purchaseFeesTotal',
      key: 'purchaseFeesTotal',
      cellRenderer: ({ rowData }) => 'R$ ' + formatMoney(rowData.purchaseFeesTotal),
    },
    { title: 'Vencimento do boleto', dataKey: 'slipDueDate', key: 'slipDueDate', width: 200 },
    { title: 'Situação', dataKey: 'situation', key: 'situation' },
    {
      title: 'Forma de pagamento',
      dataKey: 'paymentMethod',
      key: 'paymentMethod',
      width: 200,
      cellRenderer: ({ rowData }) => (
        <Box sx={{ display: 'flex', gap: '8px' }}>
          {rowData.paymentMethod?.map((payment, index) => (
            <PaymentType paymentType={payment} key={`${rowData.id}_payment_${index}`} />
          ))}
        </Box>
      ),
    },
    {
      title: 'Ações',
      dataKey: 'actions',
      key: 'action',
      resizable: false,
      width: 72,
      headerRenderer: () => (
        <Tooltip title='Seletor de colunas' placement='top'>
          <Button
            onClick={openColumnsConfiguration}
            sx={(theme: Theme) => ({
              borderRadius: '50%',
              background: theme.palette.grey[100],
              height: '32px',
              width: '32px',
              minWidth: 'unset',
              '&:hover': {
                background: theme.palette.grey[50],
              },
            })}
          >
            <Icon icon={faColumns} sx={(theme: Theme) => ({ color: theme.palette.grey[700] })} />
          </Button>
        </Tooltip>
      ),
    },
  ]
}
export const purchaseTableColumnsOptions = [
  { active: true, id: 'purchaseNumber', title: 'Número da compra' },
  { active: true, id: 'companyTradeName', title: 'Empresa' },
  { active: true, id: 'purchaseDate', title: 'Data da compra' },
  { active: true, id: 'purchaseOrdersTypes', title: 'Tipo' },
  { active: true, id: 'creditDate', title: 'Data do crédito' },
  { active: true, id: 'pendency', title: 'Pendência' },
  { active: true, id: 'responsibleUser', title: 'Responsável' },
  { active: true, id: 'purchaseStatus', title: 'Status' },
  { active: true, id: 'purchaseValue', title: 'Valor do pedido' },
  { active: false, id: 'clientCode', title: 'Código do cliente' },
  { active: false, id: 'purchaseEndDate', title: 'Data liquidação pedido' },
  { active: false, id: 'purchaseRechargeValue', title: 'Valor recarga' },
  { active: false, id: 'projectedBalance', title: 'Saldo Projetado' },
  { active: false, id: 'purchaseFeesTotal', title: 'Total taxas' },
  { active: false, id: 'purchaseComplementaryValue', title: 'Valor complementar' },
  { active: false, id: 'situation', title: 'Situação' },
  { active: false, id: 'slipDueDate', title: 'Vencimento do boleto' },
  { active: false, id: 'paymentMethod', title: 'Forma de pagamento' },
  { active: true, id: 'action', title: 'Ações', disabled: true },
]
