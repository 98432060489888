import { useQuery } from '@tanstack/react-query'
import { IFetchHook } from '../interfaces/iFetchHook'
import api from '@/infra/api'

const defaultValue = {
  data: [],
  pageNumber: 1,
  pageSize: 10,
  totalItens: 0,
  totalPages: 1,
}
export const useCompanies = ({ filter, pagination, immutableQuery }: IFetchHook) => {
  const { data, ...rest } = useQuery({
    queryKey: ['companies', filter, pagination, immutableQuery],
    queryFn: () =>
      api.get(`/resale-bff/companies`, {
        params: { ...pagination, ...filter, ...immutableQuery },
      }),
    meta: {
      silent: true,
    },
  })
  return {
    companies: data?.data || defaultValue,
    ...rest,
  }
}
