import { useContext, useMemo } from 'react'
import { AutoTableVirtualized } from '@stationkim/front-ui'
import { IOrder } from '../../hooks/useOrderList'
import { getInnerTableColumns } from './utils/innerTableColumns'
import { ListOrderContext } from '../../listOrders'
import { InnerTableContainer } from './styles'
import { AttributionMenu } from './components/attributionMenu/attributionMenu'

interface InnerTableProps {
  orders: IOrder[]
  purchaseId: string
}

const handleCheckAllOrders = ({
  orders,
  purchaseId,
  setSelectedPurchaseOrders,
}: {
  orders: IOrder[]
  purchaseId: string
  setSelectedPurchaseOrders: React.Dispatch<React.SetStateAction<Record<string, IOrder[]>>>
}) => {
  setSelectedPurchaseOrders((selectedList) => {
    if (selectedList[purchaseId]?.length === orders.length) return { ...selectedList, [purchaseId]: [] }
    return { ...selectedList, [purchaseId]: orders }
  })
}

export const InnerTable = ({ orders, purchaseId }: InnerTableProps) => {
  const { setOrderToDetail, selectedPurchaseOrders, setSelectedPurchaseOrders } = useContext(ListOrderContext)

  const columns = useMemo(() => {
    return getInnerTableColumns({
      setOrderToDetail,
      purchaseId,
      selectedPurchaseOrders,
      isAllChecked: orders.length === selectedPurchaseOrders[purchaseId]?.length,
      setSelectedPurchaseOrders: (order: IOrder, all?: boolean) => {
        if (all) handleCheckAllOrders({ orders, purchaseId, setSelectedPurchaseOrders })
        else
          setSelectedPurchaseOrders((selectedList) => {
            const newSelected = { ...selectedList }
            const actualSelected = newSelected[purchaseId] || []
            const isSelected = actualSelected.some((selectedOrder) => selectedOrder.id === order.id)
            newSelected[purchaseId] = isSelected
              ? actualSelected.filter((selected) => selected.id !== order.id)
              : [...actualSelected, order]
            return newSelected
          })
      },
    })
  }, [setOrderToDetail, selectedPurchaseOrders, purchaseId, setSelectedPurchaseOrders, orders])

  const resetSelected = () => setSelectedPurchaseOrders((selectedList) => ({ ...selectedList, [purchaseId]: [] }))

  return (
    <InnerTableContainer>
      <AttributionMenu
        onRemove={resetSelected}
        onCancel={resetSelected}
        onAttribution={resetSelected}
        ordersList={selectedPurchaseOrders[purchaseId]}
        open={selectedPurchaseOrders[purchaseId]?.length > 0}
        orderCount={selectedPurchaseOrders[purchaseId]?.length || 0}
        purchaseId={purchaseId.replace('_orders', '')}
      />
      <AutoTableVirtualized
        columns={columns}
        rows={orders}
        visibleRows={orders.length}
        variant='report'
        headerRowHeight={36}
        ignoreFunctionInColumnCompare={false}
        rowRenderer={({ rowData, cells, columns }) => {
          const orderLink = `/pedidos/recarga#orderId="${rowData.id}"&purchaseId="${rowData.purchaseId}"&isDealerOrder=false`
          return cells.map((cell, index) => {
            const shouldPreventLink = columns[index]?.preventLink
            if (shouldPreventLink) return cell
            return (
              <a
                href={orderLink}
                key={`purchase_${rowData.purchaseId}_order_${rowData.id}_cell_${index}`}
                style={{ all: 'unset', display: 'block', height: '55px' }}
              >
                {cell}
              </a>
            )
          })
        }}
      />
    </InnerTableContainer>
  )
}
