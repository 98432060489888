import { Box, Multiselect, Select } from '@stationkim/front-ui'
import { Controller, UseFormReturn } from 'react-hook-form'
import { orderStatusOptions, orderTypesOptions } from './utils/options'
import { Label } from '@/presentation/components'
import { CalendarField } from '@/presentation/components/calendarField/calendarField'

interface FilterFormProps {
  form: UseFormReturn
  companiesOptions: { value: string; label: string }[]
  operatorsOptions: { value: string; label: string }[]
  id?: string
}

export const FilterForm = ({ form, id, companiesOptions, operatorsOptions }: FilterFormProps) => {
  if (!form) return
  const { control } = form

  return (
    <form style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <Controller
          control={control}
          name='dataMinima'
          render={({ field: { onChange, value, ref } }) => {
            return (
              <CalendarField
                id={id + 'dataMinima'}
                label='De'
                ref={ref}
                onChange={onChange}
                value={value}
                disableFuture
              />
            )
          }}
        />
        <Controller
          control={control}
          name='dataMaxima'
          render={({ field: { onChange, value, ref } }) => {
            return (
              <CalendarField
                id={id + 'dataMaxima'}
                label='Até'
                disableFuture
                ref={ref}
                onChange={onChange}
                value={value}
              />
            )
          }}
        />
      </Box>
      <Controller
        control={control}
        name='statusPedido'
        render={({ field: { onChange, value, ref } }) => {
          return (
            <Select
              id={id + 'statusPedido'}
              label='Status'
              ref={ref}
              onChange={onChange}
              value={value}
              options={orderStatusOptions}
            />
          )
        }}
      />
      <Controller
        control={control}
        name='tipoPedido'
        render={({ field: { onChange, value, ref } }) => {
          return (
            <Select
              id={id + 'tipoPedido'}
              label='Tipo de pedido'
              ref={ref}
              onChange={onChange}
              value={value}
              options={orderTypesOptions}
            />
          )
        }}
      />
      <Label>Operadoras</Label>
      <Controller
        control={control}
        name='guidsOperadora'
        render={({ field: { onChange, value } }) => {
          return <Multiselect id={id + 'guidsOperadora'} onChange={onChange} value={value} options={operatorsOptions} />
        }}
      />
      <Controller
        control={control}
        name='guidEmpresa'
        render={({ field: { onChange, value, ref } }) => {
          return (
            <Select
              id={id + 'guidEmpresa'}
              label='Empresa'
              ref={ref}
              onChange={onChange}
              value={value}
              options={companiesOptions}
            />
          )
        }}
      />
      <Controller
        control={control}
        name='dataCredito'
        render={({ field: { onChange, value, ref } }) => {
          return (
            <CalendarField
              id={id + 'dataCredito'}
              label='Data do crédito'
              ref={ref}
              onChange={onChange}
              value={value}
            />
          )
        }}
      />
    </form>
  )
}
