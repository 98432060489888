import { Chip, styled } from '@mui/material'

export const StyledChip = styled(
  Chip /* , {
  shouldForwardProp: (prop) => prop !== 'hasPendency',
} */,
)(() => ({
  height: '24px',
  borderRadius: '16px',
  padding: '3px 4px',
  cursor: 'pointer',
}))
